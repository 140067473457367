export enum SubscriptionType {
  PREVIEW = "PREVIEW",
  STANDARD = "STANDARD",
}
export interface ICompany {
  name: string;
  industry: string;
  numberOfEmployees: number;
  subscriptions: SubscriptionType[];
}

export interface CompanyResponse {
  name: string;
  industry: string;
  number_of_employees: number;
  preferred_currency: string;
  subscriptions: SubscriptionType[];
}

export type UpdateCompanyDetailsRequest = {
  name: string;
  industry: string;
  numberOfEmployees: number;
  preferredCurrency: string;
};
