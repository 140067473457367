import { useMutation, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import classnames from "classnames";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { SurveyService } from "../api/SurveyService";
import { Header } from "../components/Header/Header";
import { InvalidSurvey } from "../components/InvalidSurvey/InvalidSurvey";
import { SurveyFlow } from "../components/SurveyFlow/SurveyFlow";
import { SurveyThankYou } from "../components/SurveyThankYou/SurveyThankYou";
import { LoadingScreen } from "../components/ui/LoadingScreen";
import { Modal } from "../components/ui/Modal/Modal";
import { ProgressBar } from "../components/ui/ProgressBar/ProgressBar";
import { Snackbar } from "../components/ui/Snackbar/Snackbar";
import { Typography } from "../components/ui/Typography/Typography";
import { IconTypes } from "../components/ui/icons";
import { Icon } from "../components/ui/icons/Icon";
import { useLanguages } from "../hooks/useLanguages";
import useTranslation from "../hooks/useTranslation";
import Survey from "../models/survey";
import { LanguageDirection } from "../providers/LanguageProvider";
import { ErrorResponse } from "../types/error";
import { GetSurveyResponse, SurveyErrorCodes } from "../types/survey";
import {
  SURVEY_INVITE_TOKEN_KEY,
  getBrowserFingerprint,
  hasSocialMediaReferrer,
  setBrowserFingerprint,
} from "../utils/survey.utils";
import Confetti from "./../styles/illustrations/Confetti.png";
import "./SurveyPage.scss";

export const SurveyPage = () => {
  const { t } = useTranslation("surveyPage");
  const [survey, setSurvey] = useState<Survey>();
  const [progress, setSurveyProgress] = useState(0);
  const surveyService = new SurveyService(false);
  const [showThankYouScreen, setShowThankYouScreen] = useState(false);
  const [snackBarOpen, setSnackbarOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);
  const [progressInfoOpen, setProgressInfoOpen] = useState(false);
  const surveyMidpoint = survey?.getSegmentMidpoint();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState("");
  const [fingerprintVerified, setFingerprintVerified] = useState(false);
  const [canTakeSurvey, setCanTakeSurvey] = useState(false);
  const { getLanguageDirection, isPrimaryFontSupported } = useLanguages();
  const languageDirection = getLanguageDirection();

  const inviteToken = searchParams.get(SURVEY_INVITE_TOKEN_KEY);

  const {
    data: surveyData,
    isLoading,
    error: surveyStartError,
  } = useQuery<GetSurveyResponse, AxiosError>(
    ["survey"],
    () => surveyService.getSurvey(inviteToken || ""),
    {
      enabled: canTakeSurvey,
      retry: false,
    }
  );

  const submitSurveyMutation = useMutation({
    mutationFn: () =>
      surveyService.submitSurvey(inviteToken || "", {
        answers: survey?.getAnswers(),
      }),
  });

  useEffect(() => {
    if (surveyData) {
      setSurvey(new Survey(surveyData));
    }
  }, [surveyData]);

  useEffect(() => {
    // get browser fingerprint from local storage
    const browserFingerprint = getBrowserFingerprint(inviteToken);
    if (!browserFingerprint && !hasSocialMediaReferrer() && !!inviteToken) {
      setCanTakeSurvey(true);
    } else {
      if (browserFingerprint) {
        setError(SurveyErrorCodes.ALREADY_USED);
      }
      setCanTakeSurvey(false);
    }

    setFingerprintVerified(true);
  }, [inviteToken]);

  useEffect(() => {
    if (surveyStartError) {
      const errorData = surveyStartError?.response
        ?.data as ErrorResponse<SurveyErrorCodes>;
      setError(errorData?.extra?.code || "error");
    }
  }, [surveyStartError]);

  const handleSubmitSurvey = async () => {
    let success = false;
    await submitSurveyMutation.mutateAsync(undefined, {
      onSuccess: () => {
        success = true;
        if (survey?.isPublic) {
          setBrowserFingerprint(inviteToken);
        }
      },
      onError: () => {
        setSnackbarOpen(true);
        success = false;
      },
    });

    return success;
  };

  useEffect(() => {
    progress === surveyMidpoint
      ? setProgressInfoOpen(true)
      : setProgressInfoOpen(false);
  }, [progress, surveyMidpoint]);

  const handleInfoClick = () => {
    setInfoOpen(true);
  };

  const errorPage = () => {
    if (error === SurveyErrorCodes.ALREADY_USED) {
      return (
        <InvalidSurvey
          className="SurveyPage__page"
          title={t("usedToken.title")}
          info={t("usedToken.info")}
        />
      );
    } else if (error === SurveyErrorCodes.CLOSED) {
      return (
        <InvalidSurvey
          className="SurveyPage__page"
          title={t("closedSurvey.title")}
          info={t("closedSurvey.info")}
        />
      );
    } else {
      return (
        <InvalidSurvey
          className="SurveyPage__page"
          title={t("invalid.title")}
          info={t("invalid.info")}
        />
      );
    }
  };

  const progressInfoClassNames = classnames("SurveyPage__progressInfo", {
    "SurveyPage__progressInfo--rtl":
      languageDirection === LanguageDirection.RTL,
  });

  const infoModalClassNames = classnames("SurveyPage__info", {
    "SurveyPage__info--rtl": languageDirection === LanguageDirection.RTL,
  });

  const classes = classnames("SurveyPage", {
    "SurveyPage--backupFont": !isPrimaryFontSupported(),
    "SurveyPage--loading":
      (isLoading && canTakeSurvey) || submitSurveyMutation.isLoading,
  });

  return (
    <div className={classes}>
      <Header
        showInfo={false}
        showAction={canTakeSurvey && progress !== 0}
        showProgress={progress !== 0}
        progress={progress}
        actionLabel={t("anonymityAction")}
        onActionClick={handleInfoClick}
        stackItemsInMobile
      />
      <Modal
        open={infoOpen}
        onClose={() => setInfoOpen(false)}
        light={true}
        containerClassName={infoModalClassNames}
      >
        <div>
          <Icon type={IconTypes.Lock}></Icon>
        </div>
        <div className="SurveyPage__info__text">
          <Typography tagVariant="h3" desktop="body2" weight="bold">
            {t("startInfo.security.title")}
          </Typography>
          <Typography tagVariant="p" desktop="caption">
            {t("startInfo.security.description")}
          </Typography>
        </div>
      </Modal>
      <Modal
        className={progressInfoClassNames}
        containerClassName="SurveyPage__progressInfo__text"
        open={progressInfoOpen}
        onClose={() => {
          setProgressInfoOpen(false);
        }}
        backgroundImage={Confetti}
        light
      >
        <Typography tagVariant="h3" desktop="body2" weight="bold">
          {t("surveyProgress.title")}
        </Typography>
        <Typography tagVariant="p" desktop="caption">
          {t(`surveyProgress.description`)}
        </Typography>
      </Modal>
      {isLoading && canTakeSurvey && (
        <LoadingScreen active={isLoading} text={t("loading.survey")} />
      )}
      {submitSurveyMutation.isLoading && (
        <LoadingScreen
          active={submitSurveyMutation.isLoading}
          text={t("loading.submit")}
        />
      )}
      {(surveyStartError || (fingerprintVerified && !canTakeSurvey)) &&
        errorPage()}
      {survey && !showThankYouScreen && (
        <SurveyFlow
          survey={survey}
          className="SurveyPage__page"
          setShowThankYouScreen={setShowThankYouScreen}
          setSurveyProgress={setSurveyProgress}
          onSurveySubmit={handleSubmitSurvey}
          progress={progress}
        />
      )}
      {showThankYouScreen && <SurveyThankYou className="SurveyPage__page" />}
      <Snackbar
        open={snackBarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={t("error.submit")}
        autoHideDuration={null}
      />
      {progress !== 0 && (
        <div className="SurveyPage__progress">
          <ProgressBar progress={progress} vertical length="80vh" />
        </div>
      )}
    </div>
  );
};
