import { Skeleton, Typography } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";
import { ICompany } from "../../types/company";
import { Card } from "../ui/Card/Card";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./Company.scss";

interface CompanyProps {
  company?: ICompany;
  isLoading?: boolean;
  error?: boolean;
}

export const Company = ({
  company,
  isLoading = false,
  error = false,
}: CompanyProps) => {
  const { t } = useTranslation("settingsPage");

  const renderIcon = () => {
    if (isLoading) {
      return <Skeleton variant="rectangular" className="Company__icon" />;
    }
    if (!error) {
      return <Icon className="company__icon" type={IconTypes.Company} />;
    }
  };

  const subscriptions = company?.subscriptions
    .map((item) => item.charAt(0) + item.slice(1).toLowerCase())
    .join(", ");

  return (
    <Card className="Company">
      {renderIcon()}
      {!isLoading && !error && (
        <div className="Company__info">
          <Typography className="Company__info__name">
            {company?.name}
          </Typography>

          <div className="Company__info--secondary">
            <Typography>
              {company?.industry} • {company?.numberOfEmployees}{" "}
              {t(`company.employees`)} • {subscriptions}{" "}
              {t(`company.subscription`, {
                count: company?.subscriptions.length,
              })}
            </Typography>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="Company__info">
          <Skeleton variant="text" className="Company__info__name" />
          <Skeleton variant="text" className="Company__info--secondary" />
        </div>
      )}
      {error && <Typography variant="h3">{t(`company.error`)}</Typography>}
    </Card>
  );
};
