import { SubscriptionType } from "./company";

export interface IUser {
  name: string;
  email: string;
  language: string;
  companyName?: string; // TO BE DEPRECATED
  company: {
    name: string;
    subscriptions: SubscriptionType[];
  };
}

export interface UserResponse {
  name: string;
  email: string;
  language: string;
  company_name?: string; // TO BE DEPRECATED
  company: {
    name: string;
    subscriptions: SubscriptionType[];
  };
}

export enum SubscriptionActions {
  "LAUNCH_SURVEY" = "LAUNCH_SURVEY",
  "HR_INTEGRATION" = "HR_INTEGRATION",
}

export const AvailableStandardActions: SubscriptionActions[] = [
  SubscriptionActions.LAUNCH_SURVEY,
  SubscriptionActions.HR_INTEGRATION,
];

export const AvailablePreviewActions: SubscriptionActions[] = [];

export enum InviteErrorCodes {
  EMAIL_MISMATCH = "EMAIL_MISMATCH",
  INVALID = "INVITE_TOKEN_INVALID",
}
