import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { CompanyService } from "../api/CompanyService";
import { Company } from "../components/Company/Company";
import { Integration } from "../components/Integration/Integration";
import { useAuth } from "../hooks/useAuth";
import useTranslation from "../hooks/useTranslation";
import { CompanyResponse, ICompany } from "../types/company";
import { SubscriptionActions } from "../types/user";
import "./SettingsPage.scss";

export const SettingsPage = () => {
  const { t } = useTranslation("settingsPage");
  const companyService = new CompanyService();
  const [company, setCompany] = useState<ICompany>();
  const { authInfo } = useAuth();

  const {
    data: companyData,
    isLoading,
    error,
  } = useQuery<CompanyResponse, AxiosError>(["company"], () =>
    companyService.getCompany()
  );

  const hasAccessToIntegrations = authInfo.user
    ? authInfo.user.hasAccess(SubscriptionActions.HR_INTEGRATION)
    : true;

  useEffect(() => {
    if (companyData) {
      setCompany({
        name: companyData.name,
        industry: companyData.industry,
        numberOfEmployees: companyData.number_of_employees,
        subscriptions: companyData.subscriptions,
      });
    }
  }, [companyData]);

  return (
    <div className="SettingsPage">
      <Typography variant="h5" className="SettingsPage__title">
        {t("title.company")}
      </Typography>
      <Company company={company} isLoading={isLoading} error={!!error} />

      {hasAccessToIntegrations && (
        <>
          <Typography variant="h5" className="SettingsPage__title">
            {t("title.integrations")}
          </Typography>
          <Integration />
        </>
      )}

      <Typography color="secondary" className="SettingsPage__version">
        {process.env.REACT_APP_RELEASE}
      </Typography>
    </div>
  );
};
