import {
  Checkbox,
  FormControl,
  InputLabel,
  Select as MUISelect,
  SelectProps as MUISelectProps,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import classNames from "classnames";
import { ReactNode, useState } from "react";
import { Typography } from "../Typography/Typography";
import "./Select.scss";

export interface SelectItem {
  label: string;
  value: any;
}

export interface SelectProps extends MUISelectProps {
  items?: string[] | SelectItem[];
  closeOnClick?: boolean;
}

export const Select = ({
  id,
  label,
  defaultOpen = false,
  children,
  className,
  onChange,
  labelId,
  items,
  multiple,
  renderValue,
  size = "small",
  value,
  closeOnClick = true,
  ...rest
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  // check if items have key values or are a simple array of strings
  const hasKeyValues =
    items?.[0]?.hasOwnProperty("value") && items?.[0]?.hasOwnProperty("label");

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleChange = (
    event: SelectChangeEvent<unknown>,
    child: ReactNode
  ) => {
    if (closeOnClick) {
      handleClose();
    }

    onChange?.(event, child);
  };

  const inputClasses = classNames("Select__input", {
    "Select__input--open": isOpen,
  });

  const containerClasses = classNames("Select", className, {
    "Select--noLabel": !label,
  });

  const renderMultipleValue = (selectedValues: unknown) => {
    if (!Array.isArray(selectedValues)) {
      return null;
    }

    if (selectedValues.length > 2) {
      return (
        <Typography desktop="body2">
          {`${selectedValues.length} values selected`}
        </Typography>
      );
    }

    if (hasKeyValues) {
      // find the selected labels in the items array
      selectedValues = (items as SelectItem[])
        .filter((v) => (selectedValues as string[]).includes(v.value))
        .map((v) => v.label);
    }

    return (
      <Typography desktop="body2">
        {(selectedValues as string[]).join(", ")}
      </Typography>
    );
  };

  return (
    <FormControl className={containerClasses} fullWidth size={size}>
      <InputLabel className="Select__label" id={labelId || id}>
        {label}
      </InputLabel>
      <MUISelect
        className={inputClasses}
        defaultOpen={defaultOpen}
        id={id}
        labelId={labelId || id}
        multiple={multiple}
        onChange={handleChange}
        onClose={handleClose}
        onOpen={handleOpen}
        renderValue={multiple ? renderMultipleValue : undefined}
        value={value}
        {...rest}
      >
        {items?.map((item, index) => {
          const key = `${item}${index}`;
          const itemValue = hasKeyValues
            ? (item as SelectItem).value
            : (item as string);
          const itemLabel = hasKeyValues
            ? (item as SelectItem).label
            : (item as string);
          const isChecked =
            multiple && Array.isArray(value) && value.includes(itemValue);

          return (
            <MenuItem value={itemValue} key={key}>
              {multiple && Array.isArray(value) && (
                <Checkbox checked={isChecked} value={itemValue} />
              )}
              {itemLabel}
            </MenuItem>
          );
        })}
        {!items && children}
      </MUISelect>
    </FormControl>
  );
};
