import { Checkbox, FormControlLabel } from "@mui/material";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import useTranslation from "../../hooks/useTranslation";
import ChecklistIllustration from "../../styles/illustrations/ChecklistIllustration.png";
import { SurveyInfo } from "../SurveyInfo/SurveyInfo";
import { SurveyLanguagePicker } from "../SurveyLanguagePicker/SurveyLanguagePicker";
import { Button } from "../ui/Button/Button";
import { Card } from "../ui/Card/Card";
import { Link } from "../ui/Link/Link";
import { Paper } from "../ui/Paper/Paper";
import { Typography } from "../ui/Typography/Typography";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./StartSurvey.scss";

export interface StartSurveyProps {
  className?: string;
  dueDate: string;
  isPreview?: boolean;
  onStartClick?: () => void;
  buttonLabel?: string;
  isPublic?: boolean;
}

export const StartSurvey = ({
  className,
  dueDate,
  onStartClick,
  isPreview,
  buttonLabel,
  isPublic,
}: StartSurveyProps) => {
  const { t } = useTranslation("surveyPage");
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(
    isPublic ? false : true
  );
  const [showError, setShowError] = useState(false);
  const checkboxRef = useRef<HTMLButtonElement>(null);

  const classes = classNames("StartSurvey", className, {
    "StartSurvey--preview": isPreview,
  });

  useEffect(() => {
    if (hasAcceptedTerms) {
      setShowError(false);
    }
  }, [hasAcceptedTerms]);

  const handleStartClick = () => {
    if (hasAcceptedTerms) {
      onStartClick?.();
    } else {
      setShowError(true);
      checkboxRef?.current?.focus();
    }
  };

  return (
    <div className={classes}>
      <div className="StartSurvey__background">
        <img
          src={ChecklistIllustration}
          alt=""
          className="StartSurvey__background__illustration"
        />
      </div>
      <div className="StartSurvey__intro">
        <div className="StartSurvey__intro__content">
          <Typography tagVariant="h1" desktop="display2" mobile="display2">
            {t("startInfo.title")}
          </Typography>
          <div className="StartSurvey__intro__content__language">
            <SurveyLanguagePicker />
          </div>
          <div className="StartSurvey__intro__content__time">
            <div className="StartSurvey__intro__content__time__item">
              <Icon type={IconTypes.Clock} small />
              <Typography
                className="StartSurvey__intro__content__time__item__text"
                tagVariant="p"
                desktop="caption"
                mobile="caption"
              >
                {t("startInfo.duration")}
              </Typography>
            </div>
            {!isPreview && (
              <div className="StartSurvey__intro__content__time__item">
                <Icon type={IconTypes.Calendar} />
                <Typography
                  className="StartSurvey__intro__content__time__item__text"
                  tagVariant="p"
                  desktop="caption"
                >
                  {t("startInfo.closing")} {dueDate}
                </Typography>
              </div>
            )}
          </div>
          <Paper className="StartSurvey__intro__content__container">
            <Card className="StartSurvey__intro__content__container__startInfo">
              <div className="StartSurvey__intro__content__container__startInfo__icon">
                <Icon type={IconTypes.Message} />
              </div>
              <div className="StartSurvey__text">
                <Typography tagVariant="h5" desktop="body2" weight="bold">
                  {t("startInfo.whyStatement.title")}
                </Typography>
                <Typography tagVariant="p" desktop="caption">
                  {t("startInfo.whyStatement.description")}
                </Typography>
              </div>
            </Card>
            <Card className="StartSurvey__intro__content__container__startInfo">
              <div className="StartSurvey__intro__content__container__startInfo__icon">
                <Icon type={IconTypes.Clock} />
              </div>

              <div className="StartSurvey__text">
                <Typography tagVariant="h5" desktop="body2" weight="bold">
                  {t("startInfo.time.title")}
                </Typography>
                <Typography tagVariant="p" desktop="caption">
                  {t("startInfo.time.description")}
                </Typography>
              </div>
            </Card>
            <Card className="StartSurvey__intro__content__container__startInfo">
              <div className="StartSurvey__intro__content__container__startInfo__icon">
                <Icon type={IconTypes.Lock} />
              </div>
              <div className="StartSurvey__text">
                <Typography tagVariant="h5" desktop="body2" weight="bold">
                  {t("startInfo.security.title")}
                </Typography>
                <Typography tagVariant="p" desktop="caption">
                  {t("startInfo.security.description")}
                </Typography>

                <Link href="https://alda.co/anonymity" openInNewTab>
                  {t("startInfo.security.moreInfo")}
                </Link>
              </div>
            </Card>
          </Paper>
          {isPublic && (
            <FormControlLabel
              className="StartSurvey__intro__content__terms"
              ref={checkboxRef}
              control={
                <Checkbox
                  value={true}
                  checked={hasAcceptedTerms}
                  onChange={(e) => {
                    setHasAcceptedTerms(e.target.checked);
                  }}
                  color={showError ? "error" : "primary"}
                />
              }
              label={
                <Typography desktop="body2">
                  {t("startInfo.terms.start")}{" "}
                  <a
                    href="https://alda.co/inclusion-pulse-participation"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("startInfo.terms.link")}
                  </a>
                </Typography>
              }
            />
          )}
          <Button variant="contained" onClick={handleStartClick} color="info">
            {buttonLabel}
          </Button>
          {showError && (
            <Typography desktop="caption" color="error">
              {t("startInfo.error")}
            </Typography>
          )}
        </div>
        <div>
          <SurveyInfo />
        </div>
      </div>
    </div>
  );
};
