import classNames from "classnames";
import { useLanguages } from "../../hooks/useLanguages";
import { Button } from "../ui/Button/Button";
import { Logo } from "../ui/Logo";
import { ProgressBar } from "../ui/ProgressBar/ProgressBar";
import { Typography } from "../ui/Typography/Typography";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./Header.scss";

interface HeaderProps {
  showInfo?: boolean;
  showProgress?: boolean;
  progress?: number;
  showAction?: boolean;
  onActionClick?: () => void;
  actionIconType?: IconTypes;
  actionLabel?: string;
  infoMsg?: string;
  stackItemsInMobile?: boolean;
}

export const Header = ({
  showInfo = false,
  showProgress = false,
  progress = 0,
  showAction = false,
  onActionClick,
  actionIconType = IconTypes.Close,
  actionLabel,
  infoMsg,
  stackItemsInMobile = false,
}: HeaderProps) => {
  const { getLanguageDirection } = useLanguages();
  const languageDirection = getLanguageDirection();

  const classes = classNames("Header", {
    "Header--start": !showProgress,
    "Header--stack": stackItemsInMobile,
    "Header--rtl": languageDirection === "rtl",
  });

  return (
    <header className={classes}>
      <Logo className="Header__logo" />

      <div className="Header__progress">
        {showProgress && <ProgressBar progress={progress} length="70vw" />}
      </div>
      {showInfo && (
        <div className="Header__info">
          <Typography desktop="h2" color="error">
            {infoMsg}
          </Typography>
        </div>
      )}
      {showAction && (
        <div className="Header__action">
          <Button
            color={actionLabel ? "primary" : "secondary"}
            icon={!actionLabel}
            onClick={onActionClick}
            aria-label="close"
            xSmall={actionLabel ? true : false}
            variant={actionLabel ? "outlined" : "text"}
          >
            {actionLabel}
            {!actionLabel && <Icon type={actionIconType} />}
          </Button>
        </div>
      )}
    </header>
  );
};
